// Entry point for the build script in your package.json

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// Responsive Embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();
